import { Box, Text } from 'native-base'
import React from 'react'
import BottomNav from '../components/BottomNav'

const Notifications = ({navigation}) => {
  return (
    <>
    <Box height = "88%"> 
    <Text> TODO: might make this a page for list of users? or notificatons? </Text>
    </Box>
    {/* <BottomNav navigation={navigation}/> */}
    </>
  )
}

export default Notifications